import _ from 'lodash'
import './styles.scss';

$(() => {

  const $window = $(window);
  const $mainBg = $(".main-bg");
  const $fadeOff = $(".fade-off");
  const $toTint = $(".to-tint");

  const onScroll = () => {
    let scrollTop = $window.scrollTop();
    $mainBg.css("background-position-y", Math.round(scrollTop / 2) + "px");

    const vHeight = $window.height();
    const startOff = (vHeight / 2) - 100;
    const off = 200.0;
    $fadeOff.css({
      "opacity": scrollTop > startOff ? ((off - (scrollTop - startOff)) / (off)) : 1.0,
      "transform": `translate3d(0px, ${Math.round(scrollTop / 3)}px, 0px)`
    });

    $toTint[scrollTop > 100 ? "addClass" : "removeClass"]("is-tinted");
  };

  $(window).bind('scroll', _.throttle(onScroll, 10));

  $('a[href^="#"]').click(function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      || location.hostname == this.hostname) {

      history.replaceState({}, undefined, this.href);

      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 30
        }, 1000);
        return false;
      }
    }
  });

  $("a.navbar-item").click(function () {
    $(".navbar-item").removeClass("is-active");
    $(this).toggleClass("is-active");
    $(".navbar-burger").trigger("click");
  });

  // Check for click events on the navbar burger icon
  $(".navbar-burger").click(function () {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
    $(".navbar").toggleClass("menu-open");
  });

  onScroll();
});

